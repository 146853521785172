:root{
  --primaryColor:#f14e2d;
  --bg-color:#fbe7d3;
  --shades01:#fff;
  --shades02:#878a99;
}
.mr-2{margin-right: 8px;}
.color-btn-black{color: black;}
.custom-btn , .custom-btn:hover , .custom-btn:focus{background: var(--primaryColor);border-color: var(--primaryColor);color: var(--shades01);}
/* .custom-btn:hover{background: var(--primaryColor);border-color: var(--primaryColor);color: var(--shades01);} */

.link-white , .link-white:hover{color: var(--shades01);}
.link-primary{color: var(--primaryColor);}
.link-color-custom{color: var(--shades02)};
.link-primary , .link-primary:hover, .link-primary:focus{color: var(--primaryColor) !important;}
a , a:hover{color: var(--primaryColor);}
.bg-primary{background: var(--primaryColor);background-color: var(--primaryColor) !important;}
.border-primary{border-color: var(--primaryColor) !important;}
.form-control:focus{border-color: var(--bg-color);}
.form-check-input:checked{background: var(--primaryColor);border-color:var(--primaryColor)}
.form-select:focus{border-color: var(--bg-color);}
h1, h2, h3, h4, h5, h6{color: var(--primaryColor) !important;}
.text-custom{color: var(--primaryColor) !important;}

.dropdown-menu-icon{background: none;font-size: 22px;padding: 4px 5px;width: 24px;height: 24px;line-height: unset;color: var(--primaryColor);display: flex;align-items: center;justify-content: center;border: none;}
.dropdown-menu-icon:hover{background: var(--shades01);color: var(--primaryColor);}
.dropdown-menu-icon:focus{background: var(--shades01);color: var(--primaryColor);}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){box-shadow: none !important;}

.flatpickr-months ,.flatpickr-weekdays{background: var(--primaryColor);}
.flatpickr-day.today , .flatpickr-day.today:hover, .flatpickr-day.today:focus{border-color: var(--primaryColor);background: none;}
[data-layout="vertical"][data-sidebar-size="sm"] .navbar-brand-box{background: var(--bg-color);}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{background: var(--primaryColor);border-color: var(--primaryColor);}




 .custom-checkbox{position: relative;padding-left: 24px;margin: 3px 0;width: 12px;display: flex;height: 12px;align-items: center;justify-content: center;}
 .custom-checkbox .custom-checkbox-input{position:absolute;z-index:1;top:0;left:0;width:15px;height:100%;opacity:0;cursor:pointer}
 .custom-checkbox .custom-checkbox-label{position:relative;margin:0;line-height:16px;font-size:14px}
 .custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width:15px;height:15px;background:#fff;border:1px solid var(--shades02);top: 50%;
    left: -24px;transform:translateY(-50%);border-radius:2px}
 .custom-checkbox .custom-checkbox-label:after{content:"\EB7B";position:absolute;font-family: remixicon!important;font-style: normal;width:15px;top:50%;left:-24px;transform:translateY(-50%);font-weight:500;font-size:10px;display:none;color:#fff;text-align:center}
 .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:block}
 .custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background:var(--primaryColor);border: 1px solid var(--primaryColor);}


.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle{background-color:var(--primaryColor);border-color: var(--primaryColor);}

.hamburger-icon span{background-color: var(--primaryColor);}

#page-topbar , .navbar-menu{background: var(--bg-color);}

.footer{color: var(--shades02);font-weight: 600;}

.topbar-user {background-color: var(--primaryColor);}
.logo .logo-sm{width: 50px;}
.logo .logo-lg{margin: 25px 0;display: block;}
.logo.logo-dark{}
.logo.logo-dark img{width: 100%;height: 100%;}

.navbar-menu .navbar-nav .nav-link{color: black;}
.navbar-nav{margin-bottom: 70px;}
.navbar-menu{border-right: none !important;}
.navbar-header .user-name-text{color: var(--shades01);}
[data-layout="vertical"][data-sidebar-size="sm"] .navbar-menu .navbar-nav .nav-item:hover > a.menu-link{background-color: var(--primaryColor);}
.navbar-menu .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]{color: var(--primaryColor);}
.navbar-menu .navbar-nav .nav-link.active , .navbar-menu .navbar-nav .nav-link:hover , .navbar-menu .navbar-nav .nav-sm .nav-link:hover, .navbar-menu .navbar-nav .nav-sm .nav-link.active{color: var(--primaryColor);}
.navbar-menu .navbar-nav .nav-link{font-size: 1rem;font-weight: 500;}
.navbar-header{background: var(--shades01);}

.menu-title{color: var(--primaryColor);font-size: 12px;}


.auth-page-content .text-custom{color: var(--primaryColor) !important; font-size: 26px;}

.auth-logo{width: 150px;height: 150px;}
.auth-logo img{width: 100%;height: 100%;}
.auth-one-bg .bg-overlay{background: linear-gradient(to right, #fbe7d3, #fbe7d3);}
.auth-one-bg-position{height: 540px;}
.auth-one-bg .shape > svg{fill: var(--primaryColor);}
.auth-page-wrapper .footer {left: 0;background-color: var(--primaryColor);color: var(--shades01);}
.auth-page-wrapper{background: var(--primaryColor);min-height: calc(100vh - 60px);}
.auth-page-wrapper .footer .text-muted{color: var(--shades01) !important;}

