.pagination-container {
    display: flex;
    list-style-type: none;
  }
  .pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: black;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    font-weight: 500;
    min-width: 32px;
  }
  .pagination-container .pagination-item.dots:hover {
    background-color: #FBE7D3;
    cursor: default;
  }
  .pagination-container .pagination-item:hover {
    background-color: #FBE7D3;
    cursor: pointer;
  }
  .pagination-container .pagination-item.selected {
    background-color: #FBE7D3;
    color: #F14E2D;
  }
  .pagination-container .pagination-item .arrow::before {
    position: relative;
   /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
   /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }
  .pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }
  .pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
  }
  .pagination-container .pagination-item.disabled {
    pointer-events: none;
  }
  .pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }
  .pagination-container .pagination-item.disabled:hover {
    background-color: #FBE7D3;
    cursor: default;
  }
  
  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .arrow { 
    top: 0;
  }